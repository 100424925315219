import React, { useMemo } from 'react';
import { Input as NextUIInput } from "@nextui-org/react";
import { twMerge } from 'tailwind-merge';


export const Input = (
  {
    classNames = {},
    placeholder = " ",
    labelPlacement = "outside",
    variant = "bordered",
    type = "text",
    ...props
  }) => {
  const defaultKlassName = {
    inputWrapper: 'border-[#bac2c6]',
  }

  const mergedKlassName = useMemo(() => {
    let merged = Object.assign({}, defaultKlassName);
    Object.keys(classNames).forEach((key) => {
      merged[key] = twMerge(merged[key], classNames[key]);
    });
    return merged;
  }, [classNames]);

  return (
    <NextUIInput
      classNames={mergedKlassName}
      placeholder={placeholder}
      labelPlacement={labelPlacement}
      variant={variant}
      type={type}
      {...props}
    />
  )
}