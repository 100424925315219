import React from 'react';
import { Tabs, Tab } from "@nextui-org/react";
import TabTestimonial from "./TabTestimonial";

const TabTitle = ({name, company, role}) => (
  <div>
    <div className="h7">{name}</div>
    <div className="text-base font-bold mt-2">{company}</div>
    <div className="text-base">{role}</div>
  </div>
)

const EngineerSaidTabs = () => {
  return (
    <Tabs
      aria-label="Our Engineer Said"
      placement="bottom"
      variant="light"
      classNames={{
        base: '',
        tabList: 'gap-16 items-start mx-auto',
        tab: 'h-33 opacity-50 data-[selected=true]:opacity-100',
        tabContent: 'w-full text-default-foreground22',
        cursor: 'shadow-none ',
        panel: 'pb-8 lg:mt-11',
      }}
    >
      <Tab
        key="photos"
        title={<TabTitle name="Diego Blanco" company="PCB Unlimited" role="Electronic Engineer" />}
      >
        <TabTestimonial />
      </Tab>
      <Tab
        key="music"
        title={<TabTitle name="Max Henzi" company="Pentalogix" role="Electronic Engineer" />}
      >
        <TabTestimonial />
      </Tab>
      <Tab
        key="videos"
        title={<TabTitle name="Marianna Canita" company="PCB Unlimited" role="Electronic Engineer" />}
      >
        <TabTestimonial />
      </Tab>
    </Tabs>
  )
}

export default EngineerSaidTabs;
