import React from 'react';
import PartTabs from "./main_content/PartTabs";
import EngineeringTools from "./main_content/EngineeringTools";
import Preview from 'images/home/ems-admin-preview.png';

const MainContent = () => (
  <div className="main-content">
    <div className="container">
      <div className="grid grid-cols-12 gap-4 lg:gap-20 lg:items-center">
        <div className="col-span-12 md:col-span-6 lg:col-span-5">
          <h2 className="h2 section-title"> Electronic Part Search Engine</h2>
          <PartTabs />
        </div>
        <div className="col-span-12 md:col-span-6 lg:col-span-7">
          <img src={Preview} alt="EMS Admin Preview" className="w-full" />
        </div>
      </div>
    </div>
    <div className="container">
      <EngineeringTools />
    </div>
  </div>
)

export default MainContent;
