import React from 'react';
import { Tabs, Tab, Card, CardBody } from "@nextui-org/react";
import Upload from 'src/shared/icons/Upload';

import BomCheckIco from 'images/easy_tools/bom_check.svg';
import BomQuoteIIco from 'images/easy_tools/bom_quote.svg';
import XPartWizardIco from 'images/easy_tools/x_part_wizard.svg';

import TabBomImport from "./TabBomImport";


const TitleTab = ({ico, name, description}) => (
  <div>
    <div className="border pl-7 pt-7 pr-7 pb-4 rounded-lg text-left text-lg group-data-[selected=true]:border-info-500 group-data-[selected=true]:text-info-500">
      {ico}
      <div className="name mt-4 font-medium">{name}</div>
    </div>
    <p className="mt-5 group-data-[selected=true]:text-info-500 w-48 mx-auto text-wrap text-left">
      {description}
    </p>
  </div>
)

const EasyToolTabs = () => {
  return (
    <Tabs
      aria-label="Easy Tools Tabs"
      variant="light"
      className="mt-16"
      classNames={{
        base: 'block',
        tabList: 'gap-8 items-start',
        tab: 'h-33',
        tabContent: 'w-full text-default-foreground',
        cursor: 'shadow-none',
        panel: 'pt-10',
      }}
    >
      <Tab
        key="bom-import"
        title={
          <TitleTab
            ico={<div className="mb-7"><Upload size={42} /></div>}
            name="BOM Import"
            description="The easy way to import and identify columns to quote." />
        }
      >
        <TabBomImport />
      </Tab>
      <Tab
        key="bom-quote"
        title={
          <TitleTab
            ico={<img src={BomQuoteIIco} style={{width: '40px'}} />}
            name="BOM Quote"
            description="Save time and money!" />
        }
      >
        <TabBomImport />
      </Tab>
      <Tab
        key="bom-check"
        title={
          <TitleTab
            ico={<img src={BomCheckIco} style={{width: '50px'}} />}
            name="BOM Check"
            description="Check qty per board, missing  part number and more..." />
        }
      >
        <TabBomImport />
      </Tab>
      <Tab
        key="x-parts"
        title={
          <TitleTab
            ico={<img src={XPartWizardIco} style={{width: '47px'}} />}
            name="X-Parts Wizard"
            description="Control the amount of extra parts based on footprint." />
        }
      >
        <TabBomImport />
      </Tab>
    </Tabs>
  )
}

export default EasyToolTabs;