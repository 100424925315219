import React, { useState, useEffect, useCallback } from 'react';
import { toast } from "react-toastify";
import { useDropzone } from 'react-dropzone'
import { Button, useDisclosure } from '@nextui-org/react';

// components
import LoginModal from "src/shared/components/LoginModal";
import Upload from 'src/shared/icons/Upload';
import { isUserLoggedIn } from 'src/shared/utils/session'

// apis
import { useCreateNewBomMutation } from "src/services/rtk_api/bom/BomApi";


const UploadBom = () => {
  const [acceptedFile, setAcceptedFile] = useState(null);

  const [
    createNewBom, {
      isSuccess,
      isError,
      data: new_bom
    }] = useCreateNewBomMutation({fixedCacheKey: 'shared-create-bom'});

  const {isOpen: isModalOpen, onOpen: onModalOpen, onOpenChange: onModalOpenChange} = useDisclosure();

  const onDrop = useCallback((acceptedFiles, fileRejections) => {
    // only alert customer errors if no one file was accepted
    if (acceptedFiles.length === 0 && fileRejections.length > 0) {
      fileRejections.forEach((item) => {
        const text = `${item.file.name} ` + item.errors.map((error) => error.code).join(', ')
        toast.error(text);
      });
      setAcceptedFile(null)
      return;
    }

    if (isUserLoggedIn()) { // if logged
      setAcceptedFile(null); // discard any previous file saved
      processFileSelected(acceptedFiles[0]);
    } else {
      // save file and show modal
      setAcceptedFile(acceptedFiles[0]);
      onModalOpen();
    }
  }, []);

  const processFileSelected = (file) => {
    if (!file) return;

    const form_data = new FormData()
    form_data.append('bom[file]', file)
    form_data.append('bom[origin]', 'upload')
    createNewBom({formData: form_data})
  }

  useEffect(() => {
    if (isSuccess) window.location.href = `/boms/${new_bom.id}/parse`
  }, [isSuccess])

  useEffect(() => {
    if (isError) toast.error("There was an error uploading the new BOM file\nPlease try again")
  }, [isError])

  const {getRootProps, getInputProps, open: openFileChoose} = useDropzone({
    onDrop,
    noClick: true,
    accept: {
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx', '.xls'],
      'text/csv': ['.csv'],
      'text/plain': ['.txt'],
    },
    maxFiles: 1,
  })

  const onCancelModal = () => {
    setAcceptedFile(null);
    onModalOpenChange();
  }

  const onOkModal = () => {
    onModalOpenChange();
    if (acceptedFile) {
      processFileSelected(acceptedFile);
    } else {
      setAcceptedFile(null)
      openFileChoose();
    }
  }

  const onPressUpload = () => {
    if (isUserLoggedIn()) {
      openFileChoose();
    } else {
      onModalOpen();
    }
  }

  return (
    <div {...getRootProps()}>
      <input {...getInputProps()} />
      <Button
        color="warning"
        className="h-12 px-6 w-full text-lg"
        startContent={<Upload size={30} />}
        onPress={onPressUpload}
      >
        Upload Your BOM
      </Button>
      <div className="mt-4 text-base">
        Upload your BOM in one of the following formats: .xls, xlsx, .csv
      </div>
      <LoginModal
        isOpen={isModalOpen}
        onOpenChange={onModalOpenChange}
        onOk={onOkModal}
        onCancel={onCancelModal} />
    </div>
  )
}

export default UploadBom;