import React from 'react';
import EasyToolTabs from './easy_tools/EasyToolTabs';

const EasyTools = () => {
  return (
    <div className="container easy-tools">
      <div className="h7 text-center">
        Electronic Engineering Bill of Materials
      </div>
      <h2 className="h2 text-center">Easy Tools Integrated into our Engine Software</h2>
      <EasyToolTabs />
    </div>
  )
}

export default EasyTools;
