import React              from "react";

import MainContent        from "./components/main_content/MainContent";

const BOMToolPage = () => {
  return (
    <div id="bom-tool">
      <MainContent />
    </div>
  )
}

export default BOMToolPage;
