import React, { useEffect } from 'react';
import LayoutType from 'src/shared/utils/layout_type';
import UserIcon from 'images/user-icon.png';
import Cookies from 'js-cookie';

import { Link as LinkRR } from 'react-router-dom';

import {
  NavbarItem,
  DropdownItem,
  DropdownTrigger,
  Dropdown,
  DropdownMenu,
  Button,
  User,
} from "@nextui-org/react";

import { useGetCurrentUserQuery, useLogoutMutation } from 'src/services/rtk_api/user/sessionApi';

const Anonymous = ({reloadDocument}) => {
  return (
    <NavbarItem className="">
      <Button as={LinkRR} to="/login" color="default" size="sm" className="button-login rounded">Login</Button>
    </NavbarItem>
  )
}

const MenuAccount = ({layoutType}) => {

  const {data: currentUser, error, isLoading} = useGetCurrentUserQuery();
  const [logout, {isSuccess: logoutSuccess}] = useLogoutMutation();

  const reloadFrontLink = layoutType !== LayoutType.FRONT_SITE;
  const reloadAccountLink = layoutType !== LayoutType.ACCOUNT_SITE;

  const handleLogout = (e) => {
    logout();
  }

  useEffect(() => {
    if (logoutSuccess) {
      Cookies.remove('X-User-Token');
      window.location.href = '/'
    }
  }, [logoutSuccess])

  if (isLoading || !currentUser || error) {
    return (
      <Anonymous reloadDocument={reloadFrontLink} />
    )
  }

  const avatarUrl = currentUser.avatar_url || UserIcon;

  // Note: remove padding from dropdown item so the inside link use all space available to click
  return (
    <>
      <Dropdown placement="bottom-end">
        <DropdownTrigger>
          <User
            name={currentUser.name}
            as="button"
            className="text-white"
            avatarProps={{
              src: avatarUrl,
              size: "sm",
            }}
          />
        </DropdownTrigger>
        <DropdownMenu aria-label="Profile Actions" variant="flat" itemClasses={{
          base: [
            'px-0',
            'py-0',
          ],
        }}>
          <DropdownItem key="profile" textValue="signed as" isReadOnly
                        className="h-14 gap-2 px-2 py-1.5 cursor-default">
            <p className="font-semibold">Signed in as</p>
            <p className="font-semibold">{currentUser.email}</p>
          </DropdownItem>
          <DropdownItem key="team_settings" textValue="profile">
            <LinkRR to="/account" reloadDocument={reloadAccountLink} className="px-2 py-1.5 block">Profile</LinkRR>
          </DropdownItem>
          <DropdownItem key="analytics" textValue="my BOMs">
            <LinkRR reloadDocument={reloadAccountLink} to="/account/my-boms" className="px-2 py-1.5 block">My
              BOMs</LinkRR>
          </DropdownItem>
          <DropdownItem key="settings" textValue="my settings">
            <LinkRR reloadDocument={reloadAccountLink} to="/account/preferences" className="px-2 py-1.5 block">My
              Settings</LinkRR>
          </DropdownItem>
          <DropdownItem key="logout" color="danger" className="px-2 py-1.5" onPress={handleLogout}>
            Log Out
          </DropdownItem>
          {/*<LogOut />*/}
        </DropdownMenu>
      </Dropdown>
    </>
  )
};

export default MenuAccount;
