import React from 'react';
import { Button as BaseButton, extendVariants } from '@nextui-org/react';
import { twMerge } from 'tailwind-merge'

const NewVariants = extendVariants(BaseButton, {
  variants: {
    color: {
      info: 'text-white bg-info-500',
    },
  },
})

export const Button = ({children, className, ...props}) => {
  const customClassName = 'min-h-9 font-medium'

  return (
    <NewVariants
      className={twMerge(customClassName, className)}
      {...props}
    >
      {children}
    </NewVariants>
  );
}