import React from 'react';
import { Link as LinkRR } from 'react-router-dom';
import LayoutType from 'src/shared/utils/layout_type';
import Menu from './Menu';
import MenuMobile from './MenuMobile';
import MenuAccount from './MenuAccount';
import Logo from 'images/logo.svg';

import {
  Navbar,
  NavbarBrand,
  NavbarContent,
  NavbarMenuToggle,
} from "@nextui-org/react";

import classNames from "classnames";

const Header = ({layoutType}) => {
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);

  const reloadFrontLink = layoutType !== LayoutType.FRONT_SITE;

  const klassContainer = classNames({
    'container': layoutType !== LayoutType.BOM_QUOTE,
  })

  return (
    <div className="main-layout-header">
      <div className={klassContainer}>
        <Navbar
          maxWidth="full"
          onMenuOpenChange={setIsMenuOpen}
          className="backdrop-filter-none bg-transparent py-3.5"
          classNames={{
            wrapper: "",
          }}
        >
          <NavbarContent>
            <NavbarMenuToggle
              aria-label={isMenuOpen ? "Close menu" : "Open menu"}
              className="sm:hidden"
            />
            <NavbarBrand>
              <LinkRR to="/" reloadDocument={reloadFrontLink}>
                <img src={Logo} className="h-14 logo" alt="Logo" />
              </LinkRR>
            </NavbarBrand>
          </NavbarContent>


          <NavbarContent justify="end" className="grow-0 gap-7">
            <Menu layoutType={layoutType} />
            <MenuAccount layoutType={layoutType} />
          </NavbarContent>
          <MenuMobile layoutType={layoutType} />
        </Navbar>
      </div>
    </div>
  )
}

export default Header;