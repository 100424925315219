import React from 'react';
import { Button } from 'src/shared/components/Button';
import { Link } from '@nextui-org/react';

const JoinBeta = () => (
  <div className="join-beta py-12 lg:py-0">
    <div className="container h-full flex items-center justify-center">
      <div className="xl:w-4/6">
        <div className="h7 text-white">Ready to get started?</div>
        <div className="flex flex-col md:flex-row items-center md:items-end gap-12">
          <div className="h2 text-white">
            Everything your
            <br />
            Engineer Team is looking for
          </div>
          <Button as={Link} href="mailto:support@emsadmin.com?subject=Join beta" color="info" className="h-12 px-6">Join Beta Now</Button>
        </div>
      </div>
    </div>
  </div>
)

export default JoinBeta;
