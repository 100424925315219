import React, { useState, useEffect } from 'react';
import { useFormik } from "formik"
import * as Yup from "yup"
import {
  Button,
  Card,
  CardBody,
} from '@nextui-org/react';
import { Input } from "src/shared/components/Input";

const ContactUsPage = () => {
  const formik = useFormik({
    initialValues: {
      email: '',
      firstname: '',
      lastname: '',
      phone_number: '',
      message: '',
    },
    validationSchema: Yup.object({
      email: Yup.string().email("Invalid email address").required("Required"),
      firstname: Yup.string().required("Required"),
      lastname: Yup.string().required("Required"),
      phone_number: Yup.string().required("Required"),
      message: Yup.string().required("Required"),
    }),
    onSubmit: (values) => {
      console.log(values)
    },
  });

  // const handleFormSubmit = async (e) => {
  //   e.preventDefault();
  //   if (form.getFieldsError().filter(({errors}) => errors.length).length > 0) {
  //     message.error('Please fix all errors before submitting the form');
  //     return;
  //   }
  //
  //   const values = form.getFieldsValue();
  //   console.log(values)
  // }

  return (
    <div id="contact-us">
      <div className="container">
        <Card className='card-block max-w-md'>
          <CardBody className="px-7 py-10">
            <span className='contact-us-title'>Contact Us</span>
            <h3 className='title my-1'>Let’s talk how to create <br />and manage a Bill of Materials</h3>
            <span className='subtitle'>Leave us a message</span>
            <form
              className='mt-6'
              onSubmit={formik.handleSubmit}
              name="basic"
              autoComplete="off"
            >
              <div className="grid grid-cols-2 gap-4">
                <div className="col-span-2">
                  <Input
                    variant="bordered"
                    label="Business Email"
                    placeholder=" "
                    labelPlacement="outside"
                    isInvalid={formik.touched.email && !!formik.errors.email}
                    errorMessage={formik.touched.email && formik.errors.email}
                    {...formik.getFieldProps('email')}
                  />
                </div>
                <div>
                  <Input
                    variant="bordered"
                    label="First Name"
                    placeholder=" "
                    labelPlacement="outside"
                    isInvalid={formik.touched.firstname && !!formik.errors.firstname}
                    errorMessage={formik.touched.firstname && formik.errors.firstname}
                    {...formik.getFieldProps('firstname')}
                  />
                </div>
                <div>
                  <Input
                    variant="bordered"
                    label="Last Name"
                    placeholder=" "
                    labelPlacement="outside"
                    isInvalid={formik.touched.lastname && !!formik.errors.lastname}
                    errorMessage={formik.touched.lastname && formik.errors.lastname}
                    {...formik.getFieldProps('lastname')}
                  />
                </div>
                <div className="col-span-2">
                  <Input
                    variant="bordered"
                    label="Phone Number"
                    placeholder=" "
                    labelPlacement="outside"
                    isInvalid={formik.touched.phone_number && !!formik.errors.phone_number}
                    errorMessage={formik.touched.phone_number && formik.errors.phone_number}
                    {...formik.getFieldProps('phone_number')}
                  />
                </div>
                <div className="col-span-2">
                  <Input
                    variant="bordered"
                    label="Message"
                    placeholder=" "
                    labelPlacement="outside"
                    isInvalid={formik.touched.message && !!formik.errors.message}
                    errorMessage={formik.touched.message && formik.errors.message}
                    {...formik.getFieldProps('message')}
                  />
                </div>
                <div className="col-span-2 text-right">
                  <Button color="default" type="submit" className="bg-orange-500 text-white">
                    Submit
                  </Button>
                </div>
              </div>
            </form>
          </CardBody>
        </Card>
      </div>
    </div>
  )
}

export default ContactUsPage;