import React from 'react';
import banner from 'images/how_bom_search_works/banner.png';

const HowBomSearchWorks = () => {
  return (
    <div className="how-bom-search-works">
      <div className="grid grid-cols-12">
        <div className="col-span-12 md:col-span-6 xl:col-span-5 h-full">
          <img src={banner} alt="how bom search works" className="w-full h-full" />
        </div>
        <div className="text-white col-span-12 md:col-span-6 xl:col-span-7 px-4 md:px-8 xl:px-14 py-4 md:py-10 xl:py-32 steps-wrap">
          <div className="xl:w-4/6">
            <div className="top xl:ml-16">
              <div className="h7 text-white">How BOM Search works?</div>
              <h2 className="h3 text-white mt-4">For people who want to work smarter, better and faster</h2>
            </div>
            <ul className="steps xl:mt-10">
              <li className="p-4 lg:p-8 font-bold">
                <span className="text-info font-medium mr-7 count">1</span>
                Import your BOM
              </li>
              <li className="p-4 lg:p-8 font-bold">
                <span className="text-info font-medium mr-7 count">2</span>
                Identify columns
              </li>
              <li className="p-4 lg:p-8 font-bold">
                <span className="text-info font-medium mr-7 count">3</span>
                Get a Quote!
              </li>
            </ul>
          </div>

        </div>
      </div>
    </div>
  )
}

export default HowBomSearchWorks;
