import React from "react";
import { useFormik } from "formik"
import * as Yup from "yup"
import { Input } from "@nextui-org/react";
import { Button } from 'src/shared/components/Button';
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { verify_recaptcha_token } from "src/services/api/verifiers/verifiers_requests";

const FindPart = () => {

  const { executeRecaptcha } = useGoogleReCaptcha();

  const onSearchPart = async (form_data) => {
    if (!executeRecaptcha) {
      console.log('Execute recaptcha not yet available');
      return;
    }

    const recaptcha_token = await executeRecaptcha('submitForm');

    verify_recaptcha_token(recaptcha_token).then((response) => {
      const { success } = response
      if(success) {
        const { keywords } = form_data;
        window.location.href = `/find-part/${keywords}`;
      } 
    }).catch((err) => {
      console.log(err)
    })
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      keywords: '',
    },
    validationSchema: Yup.object({
      keywords: Yup.string().required('Required').min(3, "Must be 3 characters or more"),
    }),
    onSubmit: onSearchPart,
  })

  return (
    <form 
      autoComplete="off"
      onSubmit={formik.handleSubmit}>
      <div className="flex gap-4">
        <Input 
          classNames={{
            inputWrapper: 'h-12',
          }} 
          placeholder="Enter a part number"
          isInvalid={formik.touched.keywords && !!formik.errors.keywords}
          errorMessage={formik.touched.keywords && formik.errors.keywords}
          {...formik.getFieldProps('keywords')}
        />
        <Button color="warning" className=" px-6 h-12" type="submit">Find Part</Button>
      </div>
    </form>
    

  )
}

export default FindPart;
