import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { getUserToken } from 'src/shared/utils/session'

export const sessionApi = createApi({
  reducerPath: 'sessionApi',
  baseQuery: fetchBaseQuery({
    baseUrl: '/api/v1',
    prepareHeaders: (headers, { getState }) => {
      const token = document.querySelector('meta[name="csrf-token"]').content;
      if (token) {
        headers.set('X-CSRF-Token', token)
      }
      const tokenApp = getUserToken()
      if (tokenApp) {
        headers.set('X-User-Token', tokenApp)
      }
      return headers
    }
  }),
  tagTypes: ['Session'],
  endpoints: (builder) => ({
    createNewSession: builder.mutation({
      query: ({ formData }) => ({
        url: '/login',
        method: 'POST',
        body: formData
      }),
    }),
    logout: builder.mutation({
      query: () => ({
        url: '/logout',
        method: 'DELETE',
      }),
    }),
    getCurrentUser: builder.query({
      query: () => 'current_user',
    }),
  }),
})

export const {
  useCreateNewSessionMutation,
  useLogoutMutation,
  useLazyGetCurrentUserQuery,
  useGetCurrentUserQuery,
} = sessionApi