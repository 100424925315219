import React, { useState, useEffect, useCallback } from "react";
import classNames from "classnames";
import { useDropzone } from 'react-dropzone'
import { Button, Card, CardBody, Divider, useDisclosure } from "@nextui-org/react";

import LoginModal from "src/shared/components/LoginModal";

import { activateInputFile } from "src/shared/utils/file_input";
import { isValidBomFile } from "../../../home/helpers/BomFileTypes";
import { isUserLoggedIn } from 'src/shared/utils/session'
import { useCreateNewBomMutation } from "src/services/rtk_api/bom/BomApi";

const MainContent = () => {

  const [createNewBom, {
    isSuccess,
    isError,
    data: new_bom
  }] = useCreateNewBomMutation({fixedCacheKey: 'shared-create-bom'});
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [calledBy, setCalledBy] = useState(null);
  const [uploadedFile, setUploadedFile] = useState(null);
  const {isOpen: isModalOpen, onOpen: onModalOpen, onOpenChange: onModalOpenChange} = useDisclosure();

  const onDrop = useCallback((acceptedFiles) => {
    onDragAndDropBOM(acceptedFiles)
  });
  const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop});

  const file_input_id = "upload-bom-input";

  const onUploadNewBOMFile = (selected_file) => {
    if (!selected_file) {
      alert("No file uploaded\nPlease try again")
      return;
    }

    if (!isValidBomFile(selected_file.name)) {
      alert("Invalid uploaded file\nPlease upload a valid BOM file\nSupported formats: .xls, .xlsx, .csv, .txt")
      return;
    }

    const form_data = new FormData()
    form_data.append('bom[file]', selected_file)
    form_data.append('bom[origin]', 'upload')
    createNewBom({formData: form_data})
  }

  const onCreateNewBOMFromZero = () => {
    const form_data = new FormData()
    form_data.append('bom[revision]', 'REV 1.0')
    form_data.append('bom[origin]', 'new_bom')
    form_data.append('bom[board_qtys]', '1')
    form_data.append('bom[version_status]', 'draft')
    createNewBom({formData: form_data})
  }

  const onChangeBomFile = (e) => {
    const selected_file = e.target.files[0]
    onUploadNewBOMFile(selected_file)
  }

  const onClickCreateNewBom = () => {
    setCalledBy('create_new_bom')
    setUploadedFile(null)
    if (!isUserLoggedIn()) {
      onModalOpen()
      return;
    }
    onCreateNewBOMFromZero()
  }

  const onDragAndDropBOM = (files) => {
    if (!files || files.length == 0) {
      setUploadedFile(null)
      alert("No file uploaded\nPlease try again")
      return;
    }

    if (files.length > 1) {
      setUploadedFile(null)
      alert("Only one file can be uploaded at a time\nPlease try again")
      return;
    }

    if (!isValidBomFile(files[0].name)) {
      setUploadedFile(null)
      alert("Invalid uploaded file\nPlease upload a valid BOM file\nSupported formats: .xls, .xlsx, .csv, .txt")
      return;
    }


    setCalledBy('drag_and_drop')
    setUploadedFile(files[0])

    if (!isUserLoggedIn()) {
      onModalOpen()
      return;
    }

    onUploadNewBOMFile(files[0])
  }

  const onLogin = () => {
    if (calledBy === 'drag_and_drop') onUploadNewBOMFile(uploadedFile)
    else if (calledBy === 'upload_bom') uploadBomAfterLogin()
    else if (calledBy === 'create_new_bom') onCreateNewBOMFromZero()
  }

  const uploadBomAfterLogin = () => {
    setShowLoginModal(false)
    activateInputFile(`#${file_input_id}`)
  }

  const onCancelLogin = () => {
    setShowLoginModal(false)
  }

  useEffect(() => {
    if (isSuccess) window.location.href = calledBy === 'create_new_bom' ? `/boms/${new_bom.id}` : `/boms/${new_bom.id}/parse`
  }, [isSuccess])

  useEffect(() => {
    if (isError) alert("There was an error uploading the new BOM file\nPlease try again")
  }, [isError])

  return (
    <section className="main-content">
      <div className="lg:h-[32rem] bom-tool-main-section">
        <div className="py-5 bg-white">
          <div className="container">
            <h3 className='title'>BOM Quote</h3>
          </div>
        </div>
        <div className="container">
          <div className="bom-file-block flex items-center justify-center mt-8">
            <div className="my-3 text-center lg:text-left">
              <div className="input-file-container">
                <Button className="bg-orange-500 text-white" onClick={onClickCreateNewBom}>
                  Create New BOM
                </Button>
                <input type="file" className="form-control"
                       id={file_input_id} placeholder="Bom File"
                       name="bom[file]"
                       onChange={(e) => onChangeBomFile(e)} hidden />
              </div>
            </div>
            <Divider className="my-4 max-w-24" />
            <span className="mx-2.5">or</span>
            <Divider className="my-4 max-w-24" />
            <div
              className={classNames("drag-drop-bom my-3 text-center lg:text-left", {'dragging': isDragActive})}
              {...getRootProps()}>
              <input {...getInputProps()} />
              <span>Drag and drop your BOM file here(.csv,</span> <br />
              <span>.xls, .xlsx, .txt)</span> or <a href="#" onClick={e => e.preventDefault()}>select a file</a>
            </div>
          </div>
        </div>

      </div>
      <LoginModal
        isOpen={isModalOpen}
        onOpenChange={onModalOpenChange}
        onOk={onLogin}
        onCancel={onCancelLogin} />

    </section>
  )
}

export default MainContent;