import React from 'react';
import SmartDfmIcon from 'images/home/tools-icons/smart-dfm.svg';
import PcbNavigatorIcon from 'images/home/tools-icons/pcb-navigator.svg';
import PickPlaceIcon from 'images/home/tools-icons/pick-and-place-file-builder.svg';

const EngineeringTools = () => (
  <div className="engineer-tools tools-list">
    <span className="h3 text-white title">Engineering Tools</span>
    <div className="flex flex-col lg:flex-row gap-10 lg:gap-16 xl:gap-28 justify-center">

      <div className="tool-wrap smart-dfm">
        <div className="tool-wrap-gradient h-full w-full p-8">
          <img src={SmartDfmIcon} alt="Smart DFM" className="tool-ico" />
          <div className="tool-name">Smart DFM</div>
        </div>
      </div>


      <div className="tool-wrap pcb-navigator">
        <div className="tool-wrap-gradient h-full w-full p-8">
          <img src={PcbNavigatorIcon} alt="PCB Navigator" className="tool-ico" />
          <div className="tool-name">PCB Navigator</div>
        </div>
      </div>

      <div className="tool-wrap pick-place">
        <div className="tool-wrap-gradient h-full w-full p-8">
          <img src={PickPlaceIcon} alt="Pick and Place file builder" className="tool-ico" />
          <div className="tool-name">Pick and Place File Builder</div>
        </div>
      </div>
    </div>
  </div>
)

export default EngineeringTools;