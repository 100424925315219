import React from 'react';
import { Button } from 'src/shared/components/Button';

import PartFoundAdnNoFoundIco from 'images/why_use/part-found-and-no-found.svg';
import FiveMIco from 'images/why_use/5M.svg';
import SubstituteIco from 'images/why_use/substitute.svg';

const WhyUse = () => {
  return (
    <div className="why-use">
      <div className="container">
        <div className="question">Why use EMS Admin?</div>
        <h2 className="text-center lg:w-4/6 xl:w-3/6 lg:mx-auto title-section">A intelligent software allow you create a BOM quote online automatically.</h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-10 md:gap-4 mt-8 md:mt-14 lg:mt-20 reasons">
          <div className="reason part-found-and-not-found">
            <div className="md:h-24 lg:h-24 md:flex md:items-center mb-2 lg:mb-8 icon">
              <img src={PartFoundAdnNoFoundIco} alt="Part Found and No Found" />
            </div>
            <div className="h5">Detailed Report of Found Parts in Real Time with Real Prices</div>
            {/*<div className="p-medium mt-4 details">*/}
            {/*  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna*/}
            {/*</div>*/}
          </div>
          <div className="reason five-m">
            <div className="md:h-24 lg:h-24 md:flex md:items-center mb-2 lg:mb-8 icon">
              <img src={FiveMIco} alt="+5M" />
            </div>
            <div className="h5">Parts of the Best Selection of Vendors and Manufacturers</div>
            {/*<div className="p-medium mt-4 details">*/}
            {/*  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna*/}
            {/*</div>*/}
          </div>
          <div className="reason substitute">
            <div className="md:h-24 lg:h-24 md:flex md:items-center mb-2 lg:mb-8 icon">
              <img src={SubstituteIco} alt="Part Substitute" />
            </div>
            <div className="h5">Revisions Control & Substitutes of Parts</div>
            {/*<div className="p-medium mt-4 details">*/}
            {/*  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna*/}
            {/*</div>*/}
          </div>
        </div>
        <div className="mt-4 text-center">
          {/*<Button color="info" className="px-6 h-12">*/}
          {/*  Learn More*/}
          {/*</Button>*/}
        </div>
      </div>
    </div>
  )
};

export default WhyUse;