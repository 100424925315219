import React from 'react';
import { createLucideIcon } from 'lucide-react'

// const Upload = createLucideIcon("Upload", [
//   ["path", { key: "upload-line-1", d: "M6 19H3C1.89543 19 1 18.1046 1 17V3C1 1.89543 1.89543 1 3 1H17C18.1046 1 19 1.89543 19 3V17C19 18.1046 18.1046 19 17 19H14" }],
//   ["path", { key: "upload-line-2", d: "M14 14L10.3536 10.3536C10.1583 10.1583 9.84171 10.1583 9.64645 10.3536L6 14" }],
//   ["path", { key: "upload-line-3", d: "M10 19L10 11" }],
// ]);

//ok
const Upload2 = createLucideIcon("Upload", [
  ["path", { key: "upload-line-1", d: "M7 21H5C3.89543 21 3 20.1057 3 19.0011C3 12.871 3 5 3 5C3 5 3 5 3 5C3 4.46957 3.21071 3.96086 3.58579 3.58579C3.96086 3.21071 4.46957 3 5 3H19C19.5304 3 20.0391 3.21071 20.4142 3.58579C20.7893 3.96086 21 4.46957 21 5V19C21 20.1046 20.1046 21 19 21H17" }],
  ["path", { key: "upload-line-2", d: "M16 16L12 12L8 16" }],
  ["path", { key: "upload-line-3", d: "M12 13V21" }],
]);

const Upload = createLucideIcon("Upload", [
  ["path", { key:  "upload-line-1", d: "M8 21H5C3.89543 21 3 20.1046 3 19V5C3 3.89543 3.89543 3 5 3H19C20.1046 3 21 3.89543 21 5V19C21 20.1046 20.1046 21 19 21H16" }],
  ["path", { key: "upload-line-2", d: "M16 16L12.3536 12.3536C12.1583 12.1583 11.8417 12.1583 11.6464 12.3536L8 16" }],
  ["path", { key: "upload-line-3", d: "M12 21L12 13" }],
]);

export default Upload;
