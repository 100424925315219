import React from 'react';

import arrow from 'images/distributors/arrow.png';
import avnet from 'images/distributors/avnet.png';
import coilcraft from 'images/distributors/coilcraft.png';
import digikey from 'images/distributors/digikey.png';
import futureElectronics from 'images/distributors/future-electronics.png';
import microchip from 'images/distributors/microchip.png';
import mouser from 'images/distributors/mouser.png';
import vertical from 'images/distributors/vertical.png';


const Distributors = () => {
  return (
    <div className="container pt-14 lg:pt-28 pb-20 lg:pb-36 distributors">
      <h2 className="title-section">Search Parts by Select Distributors</h2>

      <div className="grid grid-cols-2 gap-4 md:grid-cols-3 md:gap-10 lg:grid-cols-4 mt-14">
        <div className="border rounded p-8 md:p-12">
          <img src={digikey} alt="distributors digikey" className="mx-auto" />
        </div>
        <div className="border rounded p-8 md:p-12">
          <img src={arrow} alt="distributors arrow" className="mx-auto"/>
        </div>
        {/*<div className="border rounded p-8 md:p-12">*/}
        {/*  <img src={microchip} alt="distributors microchip" className="mx-auto"/>*/}
        {/*</div>*/}
        <div className="border rounded p-8 md:p-12">
          <img src={mouser} alt="distributors mouser" className="mx-auto"/>
        </div>
        {/*<div className="border rounded p-8 md:p-12">*/}
        {/*  <img src={futureElectronics} alt="distributors future" className="mx-auto"/>*/}
        {/*</div>*/}
        <div className="border rounded p-8 md:p-12">
          <img src={vertical} alt="distributors vertical" className="mx-auto"/>
        </div>
        {/*<div className="border rounded p-8 md:p-12">*/}
        {/*  <img src={coilcraft} alt="distributors coilcraft" className="mx-auto"/>*/}
        {/*</div>*/}
        {/*<div className="border rounded p-8 md:p-12">*/}
        {/*  <img src={avnet} alt="distributors avnet" className="mx-auto"/>*/}
        {/*</div>*/}
      </div>
    </div>
  )
}

export default Distributors;
