import React, { useState, useEffect } from 'react';
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Link,
} from "@nextui-org/react";
import { Input } from 'src/shared/components/Input';
import { Button } from 'src/shared/components/Button';

import { Eye } from 'lucide-react';
import { EyeOff } from 'lucide-react';
import { toast } from 'react-toastify';


import { setUserToken } from 'src/shared/utils/session'
import { useCreateNewSessionMutation, useLazyGetCurrentUserQuery } from 'src/services/rtk_api/user/sessionApi';
// import { useLazyGetCurrentUserQuery } from 'src/services/rtk_api/user/UserApi';

const LoginModal = (props) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isVisible, setIsVisible] = React.useState(false);
  const [createNewSession, {
    data: loggedUserResponse,
    isLoading: loginLoading,
    error: loginError,
    isSuccess: loginSuccess
  }] = useCreateNewSessionMutation();

  const [
    getCurrentUser,
    {
      data: currentUser,
      isLoading: currentUserLoading,
      error: currentUserError,
      isSuccess: currentUserSuccess
    }
  ] = useLazyGetCurrentUserQuery();

  const {
    // show,
    isOpen,
    onOpenChange,
    onOk,
    onCancel,
  } = props;

  const toggleVisibility = () => setIsVisible(!isVisible);

  const onSubmit = (e) => {
    e.preventDefault();
    // this could by try catched, and handle success and error separately
    createNewSession({formData: {email, password}})
  }

  useEffect(() => {
    if (loginError) {
      toast.error(loginError.data.message);
    }
    if (loginSuccess) {
      const {authentication_token} = loggedUserResponse.data
      setUserToken(authentication_token)
      getCurrentUser()
      onOk()
    }
  }, [loginSuccess, loginError])

  return (
    <Modal isOpen={isOpen} onOpenChange={onOpenChange} className="login-modal">
      <ModalContent>
        {(onClose) => (
          <>
            <ModalHeader>
              Log In
            </ModalHeader>
            <ModalBody>
              <div className="form-container" id="log-in-form-content">
                <form className="form-signin">
                  <div className="flex flex-col gap-4">
                    <Input
                      type="email"
                      label="Email Address"
                      labelPlacement="outside"
                      placeholder=" "
                      value={email}
                      name="email"
                      onValueChange={setEmail}
                      variant="bordered" />

                    <Input
                      label="Password"
                      name="password"
                      labelPlacement="outside"
                      placeholder=" "
                      value={password}
                      onValueChange={setPassword}
                      endContent={
                        <button className="focus:outline-none" type="button" onClick={toggleVisibility}
                                aria-label="toggle password visibility">
                          {isVisible ? (
                            <Eye size={16} className="text-lg text-default-400 pointer-events-none" />
                          ) : (
                            <EyeOff size={16} className="text-lg text-default-400 pointer-events-none" />
                          )}
                        </button>
                      }
                      type={isVisible ? "text" : "password"}
                      variant="bordered"
                    />


                  </div>
                  <Link className="forget-password mb-3" href="/">Forgot Password?</Link>
                  <div className="my-4 text-right">
                    <Button color="secondary" type="submit" className="" onClick={onSubmit}>
                      {loginLoading ? 'Logging in...' : 'Log in'}
                    </Button>
                  </div>
                </form>
              </div>
            </ModalBody>
          </>
        )}
      </ModalContent>
    </Modal>
  )
}

export default LoginModal;