import React, {useState} from "react";
import {
  Button,
  Link,
} from "@nextui-org/react";

import { Input } from 'src/shared/components/Input';

import { Eye } from 'lucide-react';
import { EyeOff } from 'lucide-react';


const Login = ({loading, onLogin}) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isVisible, setIsVisible] = React.useState(false);

  const toggleVisibility = () => setIsVisible(!isVisible);

  const onSubmit = (e) => {
    e.preventDefault();
    onLogin(email, password);
  } 

  return (
    <div className="form-container" id="log-in-form-content">
      <h1 className="h2 text-center">
        Log In
      </h1>
      <form className="flex flex-col gap-2 mt-10 form-signin">
        <Input
          type="email"
          name="email"
          label="Email Address"
          value={email}
          onValueChange={setEmail}
        />
        <Input
          label="Password"
          name="password"
          labelPlacement="outside"
          value={password}
          onValueChange={setPassword}
          endContent={
            <button className="focus:outline-none" type="button" onClick={toggleVisibility}
                    aria-label="toggle password visibility">
              {isVisible ? (
                <Eye size={16} className="text-lg text-default-400 pointer-events-none" />
              ) : (
                <EyeOff size={16} className="text-lg text-default-400 pointer-events-none" />
              )}
            </button>
          }
          type={isVisible ? "text" : "password"}
        />

        <div className="mt-3">
          { loading ? 
            <Button className="bg-orange-500 text-white w-full" disabled>Loggin...</Button> :
            <Button type="submit" className="bg-orange-500 text-white w-full" onClick={onSubmit}>Log in</Button>
          }
        </div>
        <Link className="forget-password" href="/">Forgot Password?</Link>
      </form>
    </div>
  )
}

export default Login;