import React from 'react';
import preview from 'images/engineer_said/preview.png';

const TabTestimonial = () => (
  <div className="grid grid-cols-1 md:grid-cols-2 testimonial">
    <div className="flex flex-col gap-6 p-10 content">
      <div className="h5">Very easy and friendly to use!</div>
      {/*<p className="text">*/}
      {/*  “ Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore*/}
      {/*  magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip.“*/}
      {/*</p>*/}
    </div>
    <img src={preview} alt="BOOM Tool preview" className="h-80 hidden md:block" />
  </div>
)

export default TabTestimonial;
