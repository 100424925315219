import React from 'react';
import EngineerSaidTabs from "./engineer_said/EngineerSaidTabs";

const EngineerSaid = () => {
  return (
    <div className="container pt-12 pb-14 lg:pt-24 lg:pb-28 engineer-said">
      <div className="flex justify-center ">
        <div className="w-full lg:w-4/6 mx-auto">
          <div className="h7">Testimonials</div>
          <h2 className="h2 mt-4">Our Engineer said</h2>
          <EngineerSaidTabs />
        </div>
      </div>
    </div>
  )
}

export default EngineerSaid;
