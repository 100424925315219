import React from 'react';
import Preview from 'images/easy_tools/bom_import_preview.png';
import { ArrowRight } from 'lucide-react';


const TabBomImport = () => {
  return (
    <div className="tab-content grid grid-cols-12 items-center">
      <div className="col-span-12 lg:col-span-5">
        <div className="h3">BOM Import</div>
        <div className="h6 mt-2">
          The easy way to import and identify columns to quote
        </div>
        {/*<div className="mt-3">*/}
        {/*  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore*/}
        {/*  magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip .*/}
        {/*</div>*/}
        <a href="mailto:support@emsadmin.com?subject=Join beta" className="text-info text-base flex items-center mt-4 font-bold">
          Free Trial Now
          <ArrowRight size={20} />
        </a>
      </div>
      <div className="col-span-12 lg:col-span-7">
        <img src={Preview} alt="BOM Import preview" className="w-full" />
      </div>
    </div>
  )
}

export default TabBomImport;
