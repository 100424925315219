import React from 'react';
import { Tabs, Tab } from "@nextui-org/react";
import FindPart from "./FindPart";
import UploadBom from "./UploadBom";

const PartTabs = () => {
  return (
    <div className="part-tabs">
      <div className="tab-tools">
        <Tabs
          aria-label="Options"
          variant="light"
          classNames={{
            tab: 'rounded-t-lg rounded-b-none h-12 px-6 font-bold  tab-item ',
            tabList: 'rounded-none tab-list',
            cursor: 'rounded-t-lg rounded-b-none bg-white',
            panel: 'p-0 rounded-b-lg rounded-tr-lg bg-white tab-panels',
            tabContent: 'tab-content',
          }}
        >
          <Tab key="bom-quote" title="BOM Quote">
            <UploadBom />
          </Tab>
          <Tab key="find-part" title="Find Part">
            <div className="part-search">
              <FindPart />
              <div className="mt-4 text-base">
                You can search by part number, partial part number, or keyword, like “S-35390A-T8T1G,” “RC0402,” or
                “Op-Amp” to find matching parts.
              </div>
            </div>
          </Tab>
        </Tabs>
      </div>
    </div>
  )
}

export default PartTabs;
