import React from "react";

import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

import MainContent from "./components/MainContent";
import Distributors from './components/Distributors';
import WhyUse from "./components/WhyUse";
import EasyTools from "./components/EasyTools";
import HowBomSearchWorks from "./components/HowBomSearchWorks";
import EngineerSaid from "./components/EngineerSaid";
import JoinBeta from "./components/JoinBeta";

const HomePage = () => {

  return (
    <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}>
      <div id="welcome">
        <MainContent />
        <Distributors />
        <WhyUse />
        <EasyTools />
        <HowBomSearchWorks />
        <EngineerSaid />
        <JoinBeta />
      </div>
    </GoogleReCaptchaProvider> 
  )
}

export default HomePage;