import React from 'react';
import { Outlet } from "react-router-dom";
import LayoutType from 'src/shared/utils/layout_type';
import Header from './components/header/Header';
import Footer from './components/footer/Footer';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'react-modern-drawer/dist/index.css'

const MainLayout = ({layoutType = LayoutType.FRONT_SITE}) => {
  return (
    <div className="ligth text-foreground bg-background main-layout">
      <Header layoutType={layoutType} />
      <Outlet />
      <Footer layoutType={layoutType} />
      <ToastContainer position="top-right" hideProgressBar autoClose={3000} draggable />
    </div>
  );
};

export default MainLayout;
