import React from 'react';
import { Link as LinkRR } from 'react-router-dom';
import { ChevronDownIcon } from "lucide-react";
import {
  NavbarContent,
  NavbarItem,
  Link,
  Dropdown,
  DropdownTrigger,
  DropdownMenu,
  DropdownItem,
} from "@nextui-org/react";
import LayoutType from 'src/shared/utils/layout_type';

const Menu = ({layoutType}) => {
  const reloadFrontLink = layoutType !== LayoutType.FRONT_SITE;
  const reloadFindPartLink = layoutType !== LayoutType.FIND_PART;

  return (
    <NavbarContent className="hidden sm:flex gap-7 menu" style={{flexGrow: 0}}>
      <NavbarItem>
        <span  className="text-white font-medium">
          ERP
        </span>
      </NavbarItem>
      <Dropdown>
        <DropdownTrigger>
          <NavbarItem>
            <span className="text-white font-medium flex gap-1 items-center cursor-pointer">
              BOM Tools <ChevronDownIcon size={16} />
            </span>
          </NavbarItem>
        </DropdownTrigger>
        <DropdownMenu aria-label="BOM Tools" variant="flat">
          <DropdownItem key="pick-and-place-file-builder" isReadOnly className="">
            <LinkRR reloadDocument={reloadFindPartLink} to="/find-part">
              Find Part
            </LinkRR>
          </DropdownItem>
          <DropdownItem key="smart-dfm" isReadOnly className="">
            <LinkRR reloadDocument={reloadFrontLink} to="/bom-tool">
              BOM Quote
            </LinkRR>
          </DropdownItem>
          <DropdownItem key="bom-check" isReadOnly className="">
            BOM Check
          </DropdownItem>
          <DropdownItem key="pcb-navigator" isReadOnly className="">
            BOM Compare
          </DropdownItem>         
        </DropdownMenu>
      </Dropdown>
      <Dropdown>
        <DropdownTrigger>
          <NavbarItem>
            <span className="text-white font-medium flex gap-1 items-center cursor-pointer">
              ENG Tools <ChevronDownIcon size={16} />
            </span>
          </NavbarItem>
        </DropdownTrigger>
        <DropdownMenu aria-label="ENG Tools" variant="flat">
          <DropdownItem key="smart-dfm" isReadOnly className="">
            Smart DFM
          </DropdownItem>
          <DropdownItem key="pcb-navigator" isReadOnly className="">
            PCB Navigator
          </DropdownItem>
          <DropdownItem key="pick-and-place-file-builder" isReadOnly className="">
            Pick and Place File Builder
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
      {/*<NavbarItem>*/}
      {/*  <Link color="foreground" as={LinkRR} to="/" className="text-white font-medium" reloadDocument={reloadFrontLink}>*/}
      {/*    About Us*/}
      {/*  </Link>*/}
      {/*</NavbarItem>*/}
      <NavbarItem>
        <Link color="foreground" as={LinkRR} to="/contact-us" href="/contact-us" className="text-white font-medium"
              reloadDocument={reloadFrontLink}>
          Contact
        </Link>
      </NavbarItem>
    </NavbarContent>
  );
};

export default Menu;